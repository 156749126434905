import React, { PureComponent } from "react";
import { CartesianGrid, Legend, Line, LineChart, ComposedChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, Rectangle } from "recharts";
import Icon from "../../components/icon";
import moment from "moment";
import OverlayLoader from "../../components/loader/OverlayLoader";

interface props {
    weatherHistory: any,
    HTData: any,
    chartsLoading: boolean,
    windData: any,
    intervalType: string,
    locationID: any
}

const StatisticsSection = ({ weatherHistory, HTData, chartsLoading, windData, intervalType, locationID }: props) => {
    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={56} y={0} dy={10} fontSize={10} textAnchor="end" fill="#ffffff" transform="rotate(50)">
                        {payload.value}
                        {/* {(intervalType === 'day') ? payload.value.substring(0, 3) : payload.value} */}
                    </text>
                </g>
            );
        }
    }
    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fontSize={11} fill="#ffffff" transform="rotate(0)">
                        {payload.value.toFixed(2)}
                        {/* {(intervalType === 'day') ? payload.value.substring(0, 3) : payload.value} */}
                    </text>
                </g>
            );
        }
    }
    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ gap: '0 10px' }}>
                {
                    payload.map((entry: any, index: any) => {
                        let lable = entry.value;
                        let icon: any = '';
                        switch (entry.value) {
                            case 'Avg Temperature':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"></path></svg>
                                break;
                            case 'Max Temperature':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                break;
                            case 'Min Temperature':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                break;
                            case 'Avg Humidity':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"></path></svg>
                                break;
                            case 'Max Humidity':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                break;
                            case 'Min Humidity':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                break;
                            default:
                                lable = entry.value
                        }
                        return (
                            <div className="d-flex justify-content-center align-items-center gap-1" key={`item-${index}`}>
                                {icon}
                                <p className="m-0 text-white text-nowrap font-size-11 font-weight-medium">{lable}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="dark-green-box">
                <h2><Icon name="temperature" /> Temperature {locationID == 4 ? '' : '& Humidity'}</h2>
                <div className="demo-graph mt-3 mb-3 position-relative" style={{ height: '350px', }}>
                    <OverlayLoader chartsLoading={chartsLoading} />


                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={100}
                            data={HTData}
                            margin={{ top: 10, right: 25, left: 0, bottom: 30, }}
                        >
                            <defs>
                                <linearGradient id="tempColor" x1="0%" x2="0%" y1="0%" y2="100%">
                                    <stop offset="0%" stopColor="#C27803" />
                                    <stop offset="100%" stopColor="#E3A008" />
                                </linearGradient>
                            </defs>
                            <defs>
                                <linearGradient id="humiColor" x1="0%" x2="0%" y1="0%" y2="100%">
                                    <stop offset="0%" stopColor="#3F83F8" />
                                    <stop offset="100%" stopColor="#76A9FA" />
                                </linearGradient>
                            </defs>

                            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.4} />
                            <XAxis dataKey="name" stroke="#0d2202" angle={90} height={68} tick={<CustomizedXAxisTick />} />
                            <YAxis stroke="#0d2202" domain={['dataMin', 'dataMax + 5']} tick={<CustomizedYAxisTick />} />
                            <Tooltip
                                contentStyle={{ backgroundColor: '#014737', border: '0', borderRadius: '5px', textAlign: 'left' }}
                                labelStyle={{ color: '#f4f4f4', fontSize: '13px', textAlign: 'left', lineHeight: 1.6, fontWeight: '700', marginBottom: '6px' }}
                                itemStyle={{ fontSize: '11px', textAlign: 'left', padding: 0, lineHeight: 1.4, color: '#ffffff', fontWeight: '500' }}
                            />

                            <Bar dataKey="Avg Temperature" fill="url(#tempColor)" stackId={'a'} radius={5} activeBar={<Rectangle radius={5} />} />
                            {
                                locationID == 4 ? null :
                                    <>
                                        <Bar dataKey="Avg Humidity" fill="url(#humiColor)" stackId={'b'} radius={5} activeBar={<Rectangle radius={5} />} />
                                        <Line type="monotone" dataKey="Max Humidity" stroke="#3F83F8" strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
                                        <Line type="monotone" dataKey="Min Humidity" stroke="#A4CAFE" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} />
                                    </>
                            }

                            <Line type="monotone" dataKey="Max Temperature" stroke="#F05252" strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey="Min Temperature" stroke="#FACA15" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} />

                            <Legend iconType="plainline" align="center" iconSize={10} content={renderLegend} />
                        </ComposedChart>
                    </ResponsiveContainer>


                </div>




                <h2 > <Icon name="wind" /> {weatherHistory?.locationName} Wind Statistics</h2>

                <div className="d-flex text-white opacity-50 gap-3 overflow-auto">
                    {windData.length > 0 ? windData?.map((wdata: any, i: any) => (
                        <div key={`wind-${i}`} className="d-flex flex-column justify-center align-items-center">
                            <p className="font-size-12 text-center">{(Math.round(wdata.speed * 36 * 100) / 100).toFixed(2)} <br /> Km/h</p>
                            {/* <div className="mb-3" style={{   transformOrigin: 'center' }} > */}
                            <div className="mb-1" style={{ transform: "rotate(" + (wdata.direction - 90) + "deg)", transformOrigin: 'center' }} >
                                <Icon name='send' size={16} />
                            </div>
                            <p className="mb-1">{parseFloat(wdata.direction).toFixed(2)}<sup>o</sup></p>
                            <p className="font-size-10 text-center text-nowrap">
                                {moment(wdata.name).format('DD-MM-YYYY')}
                            </p>

                            {/* <p className="font-size-12 text-center">{(intervalType === 'day') ? wdata.name.substring(0, 3) : wdata.name}</p> */}
                        </div>
                    ))
                        :
                        <p className="text-white">No data available!</p>
                    }

                </div>

            </div>


        </React.Fragment>
    )

}

export default StatisticsSection;