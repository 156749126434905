import React from "react";
import Search from "../search/Search";
import Notification from "../notification/Notification";
import { FaBars } from "react-icons/fa6";
import UserDropdown from "../userDropdown/UserDropdown";
import { Link } from "react-router-dom";
import { URLS } from "../../_config";
import { useUser } from "../../_common/hooks/authHook";
import LoadStateData from "../loadStateData";
interface props{
    onSearch? : (str:string)=> void
}
const Header = ({onSearch}:props) => {

    const toggleSidebar = ()=>{
        let body = document.body;
        if(body.classList.contains('vertical-collpsed')){
            body.classList.remove('vertical-collpsed');
        } else {
            body.classList.add('vertical-collpsed');
        }
    }
   
    
    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                    <Link to={URLS.DASHBOARD}  className="logo logo-dark" > 
                            <span className="logo-sm">
                                <img src="/images/logo-light.png" alt="" height="60" />
                            </span>
                            <span className="logo-lg">
                                <img src="/images/logo-light.png" alt="" height="60" />
                            </span>
                            </Link>
                            <Link to={URLS.DASHBOARD}  className="logo logo-light" > 
                   
                            <span className="logo-sm">
                                <img src="/images/logo-light.png" alt="" height="60" />
                            </span>
                            <span className="logo-lg">
                                <img src="/images/logo-light.png" alt="" height="60" />
                            </span>
                            </Link>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn" onClick={toggleSidebar}>
                        <FaBars />
                    </button>
                     {
                        onSearch &&  <Search setSearch={onSearch}></Search>
                     }
                   


                </div>

                <div className="d-flex">
                    <Notification></Notification>
                    <UserDropdown></UserDropdown>
                </div>
            </div>
            <LoadStateData/>
        </header>
    )
}

export default Header;