import React, { useEffect } from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import EmployeeList from './pages/employee/EmployeeList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ACTIONS, STORAGE_VERSION, URLS } from './_config';
import Dashboard from './pages/dashboard/Dashboard';
import EmployeeAdd from './pages/employee/EmployeeAdd';
import FarmerList from './pages/farmer/FarmerList';
import EmployeeDetails from './pages/employee/EmployeeDetails';
import FarmerDetails from './pages/farmer/farmerDetails';
import DroneImagery from './pages/reports/DroneImagery';
import IotInstallationStation from './pages/reports/iotInstallationStation/IotInstallationStation';
import FarmersListsReport from './pages/reports/FarmersListsReport';
import FarmersContactLists from './pages/reports/FarmersContactLists';
import FarmingMethods from './pages/collateral/farmingMethods/FarmingMethods';
import CropProtectionMethods from './pages/collateral/cropProtectionMethods/CropProtectionMethods';
import TrainingGroupList from './pages/trainingGroupList/TrainingGroupList';
import EventsList from './pages/events/EventsList';
import DeviceList from './pages/IoTDevice/DeviceList';
import IotInstallationStationList from './pages/IoTDevice/IotInstallationStationList';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import FarmList from './pages/farm/FarmList';
import DeviceAdd from './pages/IoTDevice/DeviceAdd';
import CropAdd from './pages/collateral/farmingMethods/addFramingMethod';
import EventsAdd from './pages/events/EventsAdd';
import EventsDetails from './pages/events/EventsDetails';
import FarmDetails from './pages/farm/FarmDetails';
import TrainingGroupAdd from './pages/trainingGroupList/TrainingGroupAdd';
import PrivateRoutes from './_config/privateRoutes';
import { EmployeeRolePermission } from './pages/employee/EmployeeRolePermission';
import RoleList from './pages/settings/Role/RoleList';
import RoleAdd from './pages/settings/Role/RoleAdd';
import RoleManagementSettings from './pages/settings/Role/RoleManagementSettings';
import IotInstallationAdd from './pages/IoTDevice/IOTInstallationAdd';
import ResetPassword from './pages/auth/RestPassword';
import ProfileAdd from './pages/employee/ProfileAdd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Logout from './pages/auth/Logout';
import DeviceUnitList from './pages/IoTDevice/DeviceUnitList';
import DeviceUnitForm from './pages/IoTDevice/DeviceUnitForm';
import CropCategoryList from './pages/collateral/cropCategory/CropCategoryList';
import CropCategoryForm from './pages/collateral/cropCategory/CropCategoryForm';
import CultivationMethodsView from './pages/collateral/farmingMethods/CultivationMethodsView';
import CultivationMethods from './pages/collateral/farmingMethods/CultivationMethods';
import CropDetails from './pages/collateral/farmingMethods/FarmingMethodDetails';
import CropProtectionMethodForm from './pages/collateral/cropProtectionMethods/CropProtectionMethodForm';
import CropProtectionMethodDetails from './pages/collateral/cropProtectionMethods/CropProtectionMethodDetails';
import DeviceCategoryList from './pages/IoTDevice/deviceTemplate/DeviceCategoryList';
import DeviceCategoryForm from './pages/IoTDevice/deviceTemplate/DeviceCategoryForm';
import DeviceCategoryDetails from './pages/IoTDevice/deviceTemplate/DeviceCategoryDetails';
import DeviceDetails from './pages/IoTDevice/DeviceDetails';
import EventAttendee from './pages/events/EventAttendee';
import PublicRoutes from './_config/publicRoutes';
import Page404 from './pages/404';
import FarmsListsReport from './pages/reports/FarmsListsReport';
import FarmersTrainingAttendanceReport from './pages/reports/FarmersTrainingAttendanceReport';
import ManualNotification from './pages/notificaton/ManualNotification';
import NotificationBroadcast from './pages/notificaton/NotificationBroadcast';
import ManualNotificationDetails from './pages/notificaton/ManualNotificationDetails';
import InviteFarmerList from './pages/notificaton/InviteFarmerList';
import InviteFarmer from './pages/notificaton/InviteFarmer';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import EmailSettingsList from './pages/settings/email/EmailSettingsList';
import EmailSettingsForm from './pages/settings/email/EmailSettingsForm';

function App() {
	const dispatch = useDispatch();

	const version = localStorage.getItem('STORAGE_VERSION');
	const storageDate: any = localStorage.getItem('STORAGE_DATE');


	useEffect(() => {
		if (version !== STORAGE_VERSION || storageDate !=  moment().format('YYYY-MM-DD')) {
			dispatch({ type: ACTIONS.RESET_REDUCER })
		}
		localStorage.setItem('STORAGE_VERSION', STORAGE_VERSION);
		localStorage.setItem('STORAGE_DATE', moment().format('YYYY-MM-DD'));

		AOS.init({
			duration: 1000,
		});
		//console.log = function () { }
	}, [])

	return (
		<Router>
			<div className="App">
				<Routes>
					<Route element={<PublicRoutes />}>
						<Route path={URLS.LOGIN} element={<Login />}></Route>
						<Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />}></Route>
					</Route>

					<Route path={URLS.RESET_PASSWORD} element={<ResetPassword />}></Route>

					<Route element={<PrivateRoutes />}>
						<Route path={URLS.LOGOUT} element={<Logout />}></Route>
						<Route path={URLS.DASHBOARD} element={<Dashboard />}></Route>
						<Route path={URLS.EMPLOYEE_LIST} element={<EmployeeList />}></Route>
						<Route path={URLS.EMPLOYEE_ADD} element={<EmployeeAdd />}></Route>
						<Route path={URLS.EMPLOYEE_EDIT} element={<EmployeeAdd />}></Route>
						<Route path={URLS.EMPLOYEE_DETAILS} element={<EmployeeDetails />}></Route>
						<Route path={URLS.EMPLOYEE_PERMISSION} element={<EmployeeRolePermission />}></Route>
						<Route path={URLS.PROFILE} element={<EmployeeDetails myProfile={true} />}></Route>
						<Route path={URLS.PROFILE_EDIT} element={<ProfileAdd />}></Route>
						<Route path={URLS.FARMER_LIST} element={<FarmerList />}></Route>
						<Route path={URLS.FARMER_DETAILS} element={<FarmerDetails />}></Route>
						<Route path={URLS.FARMS.LIST} element={<FarmList />}></Route>
						<Route path={URLS.FARMS.DETAILS} element={<FarmDetails />}></Route>
						<Route path={URLS.COLLATERAL.CROP_CATEGORY.LIST} element={<CropCategoryList />}></Route>
						<Route path={URLS.COLLATERAL.CROP_CATEGORY.ADD} element={<CropCategoryForm />}></Route>
						<Route path={URLS.COLLATERAL.CROP_CATEGORY.EDIT} element={<CropCategoryForm />}></Route>

						<Route path={URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST} element={<CropProtectionMethods />}></Route>
						<Route path={URLS.COLLATERAL.CROP_PROTECTION_METHOD.ADD} element={<CropProtectionMethodForm />}></Route>
						<Route path={URLS.COLLATERAL.CROP_PROTECTION_METHOD.EDIT} element={<CropProtectionMethodForm />}></Route>
						<Route path={URLS.COLLATERAL.CROP_PROTECTION_METHOD.DETAILS} element={<CropProtectionMethodDetails />}></Route>

						<Route path={URLS.COLLATERAL.CROP.LIST} element={<FarmingMethods />}></Route>
						<Route path={URLS.COLLATERAL.CROP.ADD} element={<CropAdd />}></Route>
						<Route path={URLS.COLLATERAL.CROP.EDIT} element={<CropAdd />}></Route>
						<Route path={URLS.COLLATERAL.CROP.DETAILS} element={<CropDetails />}></Route>

						<Route path={URLS.COLLATERAL.CROP.CULTIVATION_METHODS_VIEW} element={<CultivationMethodsView />}></Route>
						<Route path={URLS.COLLATERAL.CROP.CULTIVATION_METHODS_EDIT} element={<CultivationMethods />}></Route>

						<Route path={URLS.TRAINING_GROUP} element={<TrainingGroupList />}></Route>
						<Route path={URLS.TRAINING_GROUP_ADD} element={<TrainingGroupAdd />}></Route>
						<Route path={URLS.TRAINING_GROUP_EDIT} element={<TrainingGroupAdd />}></Route>

						<Route path={URLS.EVENTS_LIST} element={<EventsList />}></Route>
						<Route path={URLS.EVENTS_DETAILS} element={<EventsDetails />}></Route>
						<Route path={URLS.EVENTS_ADD} element={<EventsAdd />}></Route>
						<Route path={URLS.EVENTS_EDIT} element={<EventsAdd />}></Route>
						<Route path={URLS.EVENT_ATTENDEE} element={<EventAttendee />}></Route>

						<Route path={URLS.DEVICE_CATEGORY_UNIT.LIST} element={<DeviceUnitList />}></Route>
						<Route path={URLS.DEVICE_CATEGORY_UNIT.ADD} element={<DeviceUnitForm />}></Route>
						<Route path={URLS.DEVICE_CATEGORY_UNIT.EDIT} element={<DeviceUnitForm />}></Route>


						<Route path={URLS.DEVICE_CATEGORY.LIST} element={<DeviceCategoryList />}></Route>
						<Route path={URLS.DEVICE_CATEGORY.DETAILS} element={<DeviceCategoryDetails />}></Route>
						<Route path={URLS.DEVICE_CATEGORY.ADD} element={<DeviceCategoryForm />}></Route>
						<Route path={URLS.DEVICE_CATEGORY.EDIT} element={<DeviceCategoryForm />}></Route>

						<Route path={URLS.DEVICE_LIST} element={<DeviceList />}></Route>

						<Route path={URLS.DEVICE_DETAILS} element={<DeviceDetails />}></Route>
						<Route path={URLS.IOT_INSTALLATION_STATIONS_LIST} element={<IotInstallationStationList />}></Route>
						<Route path={URLS.IOT_INSTALLATION_ADD} element={<IotInstallationAdd />}></Route>
						<Route path={URLS.IOT_INSTALLATION_EDIT} element={<IotInstallationAdd />}></Route>
						<Route path={URLS.DEVICE_ADD} element={<DeviceAdd />}></Route>
						<Route path={URLS.DEVICE_EDIT} element={<DeviceAdd />}></Route>

						<Route path={URLS.REPORTS.DRONE_IMAGERY} element={<DroneImagery />}></Route>
						<Route path={URLS.REPORTS.IOT_INSTALLATION_STATIONS} element={<IotInstallationStation />}></Route>
						<Route path={URLS.REPORTS.FARMERS_LIST_REPORT} element={<FarmersListsReport />}></Route>
						<Route path={URLS.REPORTS.FARMS_LIST_REPORT} element={<FarmsListsReport />}></Route>
						<Route path={URLS.REPORTS.FARMERS_CONTACT_LISTS} element={<FarmersContactLists />}></Route>
						<Route path={URLS.REPORTS.FARMERS_TRAINING_ATTENDANCE_LISTS} element={<FarmersTrainingAttendanceReport />}></Route>

						<Route path={URLS.ROLE.LIST} element={<RoleList />}></Route>
						<Route path={URLS.ROLE.ADD} element={<RoleAdd />}></Route>
						<Route path={URLS.ROLE.EDIT} element={<RoleAdd />}></Route>
						<Route path={URLS.ROLE.PERMISSION} element={<RoleManagementSettings />}></Route>

						<Route path={URLS.SETTINGS.EMAIL_SETTINGS.LIST} element={<EmailSettingsList />}></Route> 
						<Route path={URLS.SETTINGS.EMAIL_SETTINGS.EDIT} element={<EmailSettingsForm />}></Route>

						<Route path={URLS.NOTIFICATION.MANUAL_NOTIFICATION} element={<ManualNotification />}></Route>
						<Route path={URLS.NOTIFICATION.NOTIFICATION_DETAILS} element={<ManualNotificationDetails />}></Route>
						<Route path={URLS.NOTIFICATION.NOTIFICATION_BROADCAST} element={<NotificationBroadcast />}></Route>

						<Route path={URLS.NOTIFICATION.INVITE_FARMERS_LIST} element={<InviteFarmerList />}></Route>
						<Route path={URLS.NOTIFICATION.INVITE_FARMERS} element={<InviteFarmer />}></Route>
					</Route>

					<Route path={`*`} element={<Page404 />}></Route>
				</Routes>
			</div>
		</Router>

	);
}

export default App;
