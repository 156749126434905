import React, { useState } from 'react';
import './LeftPanel.scss';

import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { SlUser } from "react-icons/sl";
import { GiFarmTractor } from "react-icons/gi";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { MdOutlineEventNote } from "react-icons/md";
import { CiMicrochip } from "react-icons/ci";
import { TbReportAnalytics } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";

import Accordion from 'react-bootstrap/Accordion';
import { useUserPermissions } from '../../_common/hooks/authHook';
import { URLS } from '../../_config';
import { Link } from 'react-router-dom';
import Icon from '../icon';


const LeftPanel = () => {

    const permissions: any = useUserPermissions();

    const floatMenu = (el: any) => {
        let body = document.body;
        if (body.classList.contains('vertical-collpsed')) {
            el.closest('.accordion').getElementsByTagName('button')[0]?.click();
        }
    }


    return (
        <div className="vertical-menu">
            {(permissions && true) &&
                <div data-simplebar className="h-100">

                    <div id="sidebar-menu" className="mm-active">
                        <ul className="metismenu list-unstyled mm-show" id="side-menu">
                            {permissions?.menus?.Dashboard?.read &&
                                <li>
                                    <Link to={URLS.DASHBOARD} className=" waves-effect" aria-expanded="false">
                                        <MdOutlineDashboard />
                                        <span key="t-starter-page">Dashboard</span>
                                    </Link>
                                </li>
                            }

                            {permissions?.menus?.Employee.read &&
                                <li>
                                    <Link to={URLS.EMPLOYEE_LIST} className=" waves-effect" aria-expanded="false">
                                        <MdOutlinePeopleAlt />
                                        <span key="t-horizontal">Employee</span>
                                    </Link>
                                </li>
                            }
                            {permissions?.menus?.Farmer.read &&
                                <li>
                                    <Link to={URLS.FARMER_LIST} className=" waves-effect" aria-expanded="false">
                                        <SlUser />
                                        <span key="t-horizontal">Farmer</span>
                                    </Link>
                                </li>
                            }
                            {permissions?.menus?.Farms.read &&
                                <li>

                                    <Link to={URLS.FARMS.LIST} className=" waves-effect" aria-expanded="false">
                                        <GiFarmTractor />
                                        <span key="t-horizontal">Farms</span>
                                    </Link>
                                </li>
                            }
                            {permissions?.menus?.Collateral.read &&
                                <li>
                                    <Accordion onMouseEnter={(e) => { floatMenu(e.target) }} onMouseLeave={(e) => { floatMenu(e.target) }}>
                                        <Accordion.Item eventKey="0"  >
                                            <Accordion.Header ><MdOutlineBusinessCenter /> <span>Collateral</span></Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="sub-menu ">
                                                    <li>
                                                        <Link to={URLS.COLLATERAL.CROP_CATEGORY.LIST} >
                                                            Crop Category
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={URLS.COLLATERAL.CROP.LIST} >
                                                            Farming Methods
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={URLS.COLLATERAL.CROP_PROTECTION_METHOD.LIST} >
                                                            Crop Protection Methods
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </li>
                            }
                            {permissions.menus['Training Group'].read &&

                                <li>


                                    <Link to={URLS.TRAINING_GROUP} className=" waves-effect" aria-expanded="false">
                                        <SlPeople />
                                        <span key="t-horizontal">Training Group</span>
                                    </Link>
                                </li>
                            }
                            {permissions?.menus?.Events.read &&

                                <li>
                                    <Link to={URLS.EVENTS_LIST} className=" waves-effect" aria-expanded="false">
                                        <MdOutlineEventNote />
                                        <span key="t-horizontal">Events</span>
                                    </Link>
                                </li>
                            }
                            {permissions.menus['IoT Device'].read &&

                                <li>
                                    <Accordion onMouseEnter={(e) => { floatMenu(e.target) }} onMouseLeave={(e) => { floatMenu(e.target) }}>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header ><CiMicrochip /> <span>IoT Device</span></Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="sub-menu" aria-expanded="false">
                                                    {/* <li><Link to={URLS.DEVICE_CATEGORY_UNIT.LIST} key="t-recover-password">Device Category Unit</Link></li> */}
                                                    <li><Link to={URLS.DEVICE_CATEGORY.LIST} key="t-recover-password">Device Category</Link></li>
                                                    <li><Link to={URLS.DEVICE_LIST} key="t-recover-password">Device Management</Link></li>
                                                    <li><Link to={URLS.IOT_INSTALLATION_STATIONS_LIST} key="t-recover-password">IOT Installation Stations</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </li>
                            }
                            {permissions?.menus?.Reports.read &&

                                <li>
                                    <Accordion onMouseEnter={(e) => { floatMenu(e.target) }} onMouseLeave={(e) => { floatMenu(e.target) }}>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header ><TbReportAnalytics /> <span>Reports</span></Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="sub-menu" aria-expanded="false">
                                                    {/* <li><Link to={URLS.REPORTS.DRONE_IMAGERY} key="t-login">Drone Imagery</Link></li> */}
                                                    {/* <li><Link to={URLS.REPORTS.IOT_INSTALLATION_STATIONS} key="t-register">IOT Installation Stations</Link></li> */}
                                                    <li><Link to={URLS.REPORTS.FARMERS_LIST_REPORT} key="t-register">Farmers List Report</Link></li>
                                                    <li><Link to={URLS.REPORTS.FARMS_LIST_REPORT} key="t-register">Field Allocation Report</Link></li>
                                                    <li><Link to={URLS.REPORTS.FARMERS_CONTACT_LISTS} key="t-register">Farmers Contact Information Report</Link></li>
                                                    {/* <li><Link to={URLS.REPORTS.FARMERS_TRAINING_ATTENDANCE_LISTS} key="t-register">Farmers Training Attendance Report</Link></li> */}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </li>
                            }
                            {permissions?.menus?.Settings.read &&


                                <li>
                                    <Accordion onMouseEnter={(e) => { floatMenu(e.target) }} onMouseLeave={(e) => { floatMenu(e.target) }}>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header ><IoSettingsOutline /> <span>Settings</span></Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="sub-menu" aria-expanded="false">
                                                    <li>
                                                        <Link to={URLS.ROLE.LIST} className=" waves-effect">
                                                            <span key="t-horizontal">Roles Management</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={URLS.SETTINGS.EMAIL_SETTINGS.LIST} className=" waves-effect">
                                                            <span key="t-horizontal">Email Settings</span>
                                                        </Link>
                                                    </li>
                                                    {/* <li> <Link to="#" className=" waves-effect">

                                                        <span key="t-horizontal">Site Settings</span>
                                                    </Link></li>
                                                    <li> <Link to="#" className=" waves-effect">

                                                        <span key="t-horizontal">Email Templates</span>
                                                    </Link></li> */}

                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </li>
                            }


                            <li>
                                <Accordion onMouseEnter={(e) => { floatMenu(e.target) }} onMouseLeave={(e) => { floatMenu(e.target) }}>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header ><Icon name='notification' size={22} /> <span>Notification</span></Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="sub-menu" aria-expanded="false">
                                                <li>
                                                    <Link to={URLS.NOTIFICATION.MANUAL_NOTIFICATION} className=" waves-effect">
                                                        <span key="t-horizontal">Manual Notification</span>
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to={URLS.NOTIFICATION.INVITE_FARMERS_LIST} className=" waves-effect">
                                                        <span key="t-horizontal">Invite Farmers</span>
                                                    </Link>
                                                </li>


                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </li>


                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}

export default LeftPanel;