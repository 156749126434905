import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useDispatch } from 'react-redux';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import readXlsxFile from 'read-excel-file'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import { isImageUrl } from '../_common/functions';
import moment from "moment";
import * as ExcelJS from "exceljs";

type  row =  {
    title:string
    type:'string' | 'img' | 'number' | 'url' | 'date'
    required?:boolean
    apiKey:string,
    min?:number
    max?:number
}
type relOpt = {
    name:string
    parent:string
}

type  row1 =  {
    title:string
    type:'option'
    options?:string[]
    relativeOptions?:relOpt[]
    relWith?:string
    multi?:boolean
    required?:boolean
    apiKey:string
   
}
interface props {
    headerTitle?: string,
    show: boolean,
    onClose: (status: boolean) => void,
    dataTypes:(row | row1)[]
    sampleName?:string
    upload:(data:any)=>Promise<any>
    params:any
    onUploadComplete?:()=>void
}

/* 
const dataTypes:any = [
        {title:'title 1',type:'string', required:true},
        {title:'title 2',type:'img'},
        {title:'title 3',type:'option', options:['a','b','c'], multi:false},
    ];
*/

export default function ImportExcelModal({ headerTitle, show, onClose, dataTypes , sampleName = 'Sample', upload, params, onUploadComplete = ()=>{}}: props) {

   
    const handleClose = () => { onClose(false) };
    const user: any = useUser();
    const { showLoader, hideLoader } = useAppLoader();

    const [correctRows, setCorrectRows] = useState<any>(null);
    const [config, setConfig] = useState<any>(null);

    const limit = 10;
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [header, setHeader] = useState<any>({});
    const [data, setData] = useState<any>(null);


    const tbodyRef:any = useRef();
    const tableHeadRowRef:any = useRef();



    

    const upload3rdPartyUrl = (url:any) =>{
        return new Promise((resolve: any, reject: any) => {
            /* formApi.upload3rdPartyUrl({ fileURL: url }, (message: any, resp: any) => {
                resolve(resp.data.fileURL)
            }, (message: any) => {
                reject(message)
            }) */
        })

    };

    const AddErrorInfo = (msg:string)=>{
        return `<div class="info">
                <svg onmouseenter="showInfo(this)" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg>
                <div class="tip">
                    <p>${msg}</p>
                </div>
            </div>
        `       
    }

    

    const addImageUrlAsImage = (cell:any, url:any, required:any)=>{
        if (isImageUrl(url)) {
            let img = document.createElement('img');
            img.src = url;
            cell.appendChild(img)               
            return true;
        } else {
            if(required){
                cell.classList.add('cell-error');
                cell.innerHTML = url + AddErrorInfo('Not an image URL.<br/>For images, we require an absolute image URL with the extension.<br/>Example: https://example.com/images/image.jpg.<br/>Accepted formats include jpg, jpeg, png, webp, gif, and svg');
                return false
            } else{
                if(url != ''){
                    cell.classList.add('cell-warn');
                    cell.innerHTML = url + AddErrorInfo('Not an image URL.<br/>For images, we require an absolute image URL with the extension.<br/>Example: https://example.com/images/image.jpg.<br/>Accepted formats include jpg, jpeg, png, webp, gif, and svg');
                }
                return true;
            }
            
        }

        
    }

    const checkOptions = (cell:any, data:any, options:any, required:boolean, relWith:string | null = null, rowData:any = null)=>{

        if(relWith){
            Object.values(dataTypes).map((row:any,i:any)=>{
                if(row.title == relWith){
                    options = options.filter((opt:any)=>{
                        return opt.parent == rowData[i]
                    });
                    options = options.map((opt:any)=>{ return opt.name})
                }
            })
        }


        let _options = data.split('|');
        let checked = true
        _options.map((o:any)=>{
            if(options.indexOf(o) == -1){checked = false}
        })
        if(checked){
            cell.innerHTML = data;
            return true;
        } else {
            if (required) {
                cell.classList.add('cell-error');
                cell.innerHTML = data + AddErrorInfo(`Options Should be <b>${options.join(', ')}</b>`);
                return false
            } else {
                cell.classList.add('cell-warn');
                cell.innerHTML = data + AddErrorInfo(`Options Should be <b>${options.join(', ')}</b>`);
                return true
            }

        }

    }


    const validURL = (str:string) =>{
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    const validateCellData = (index:number,rowData:any,newRow:any)=>{
        let data = rowData[index];
        if(data == null){data = ''}
        data = data.toString();
        let field:any = dataTypes[index];
        let cellStatus = true;
        if(field){
            let newCell = newRow.insertCell(); // add td
            if (data.trim() == "" && field.required) {
                newCell.classList.add('cell-error');
                newCell.innerHTML = data + AddErrorInfo('Data Required');
                cellStatus = false;
            } else {
                switch (field.type) {
                    case 'img':
                        let status = addImageUrlAsImage(newCell, data, field.required);
                        if(!status){cellStatus = false;}
                        break;
                    case 'date':
                        if(data.trim() != ""){
                            let date:any  = moment(data, 'DD-MM-YYYY');
                            if(date.isValid()){
                                cellStatus = true;
                                newCell.innerHTML = date.format('DD-MM-YYYY');
                                rowData[index] =  date.format('YYYY-MM-DD');
                            } else {
                                cellStatus = false;
                                if(field.required){
                                    newCell.classList.add('cell-error');
                                    newCell.innerHTML = data + AddErrorInfo('Date format should be DD-MM-YYYY.');
                                } else{
                                    newCell.classList.add('cell-warn');
                                    newCell.innerHTML = data + AddErrorInfo('Date format should be DD-MM-YYYY.');
                                }
                            }
                            
                        }
                        break;
                    case 'url':
                        if(data.trim() != ""){

                            let ustatus = validURL(data);
                            if(!ustatus){
                                cellStatus = false;
                                if(field.required){
                                    newCell.classList.add('cell-error');
                                    newCell.innerHTML = data + AddErrorInfo('Not an URL.');
                                } else{
                                    newCell.classList.add('cell-warn');
                                    newCell.innerHTML = data + AddErrorInfo('Not an URL.');
                                }
                            } else {
                                cellStatus = true;
                                newCell.innerHTML = data;
                            }
                        }
                        
                        break;
                    case 'option':
                        let chStatus = false;
                        if(field.options){
                            chStatus = checkOptions(newCell, data, field.options, field.required)
                        } else {
                            chStatus = checkOptions(newCell, data, field.relativeOptions, field.required, field.relWith, rowData)
                        }
                        
                        if(!chStatus){cellStatus = false;}
                        break;
                    case 'number':
                        let nStatus = false;
                        data = data.trim();
                        if(data == '' && !field.required){
                            newCell.innerHTML = data;
                            nStatus = true;
                        } else {
                            if (!isNaN(data)) {
                                if(field.min && field.max){
                                    if (data.length < field.min || data.length > field.max) {
                                        if(field.required){
                                            newCell.classList.add('cell-error');
                                        } else {
                                            newCell.classList.add('cell-warn');
                                        }
                                        newCell.innerHTML = data + AddErrorInfo(`Value should be ${field.min} to ${field.max} characters.`);
                                    } else {
                                        newCell.innerHTML = data;
                                        nStatus = true;
                                    }
                                } else {
                                    newCell.innerHTML = data;
                                    nStatus = true;
                                }
                            } else {
                                    newCell.innerHTML = data + AddErrorInfo(`Value should be a Number.`);
                                    if(field.required){
                                        newCell.classList.add('cell-error');
                                    } else {
                                        newCell.classList.add('cell-warn');
                                    }
                            }

                        }

                        if(field.required){
                            if (!nStatus) { cellStatus = false; }
                        } else {
                            cellStatus = true;
                        }
                        
                       
                        break;
                    default:
                        newCell.innerHTML = data;
                        break;
                }
            }            
        }
        return cellStatus;
    }

    const onSelectFile = (e: any) => {
        if (e.target.files.length > 0) {
            //readCSVFile(e.target.files[0])
            readXlsxFile(e.target.files[0]).then((rows: any) => {
                //setHeader(rows.shift());
                //setData(rows);
                //setTotal(rows.length)
                tableHeadRowRef.current.innerHTML = "";
                tbodyRef.current.innerHTML = "";
                let checkedRow: any = []
                for (let row = 0; row < rows.length; row++) {
                    let rowColData = rows[row]
                    if (row == 0) {
                        for (let col = 0; col < dataTypes.length; col++) {
                            let th = document.createElement('th');
                            tableHeadRowRef.current.appendChild(th);
                            th.innerHTML = dataTypes[col].title;
                        }
                    } else {
                        let rowStatus = true;
                        let newRow = tbodyRef.current.insertRow();
                        for (let col = 0; col < dataTypes.length; col++) {
                            let cellStatus = validateCellData(col, rowColData, newRow);
                            if (!cellStatus) { rowStatus = false }
                        }
                        if (rowStatus) {
                            checkedRow.push(rowColData)
                        }
                    }
                }
                setCorrectRows(checkedRow);

                

            })
        }
    }

    const createParams = async (data:any[])=>{
        console.log(data)

        let _params:any = [];

        data.map((row:any,i:any)=>{
            console.log(row)
            let param:any = {...params};
            dataTypes.map((col:any, j:any)=>{
                param[col.apiKey] = row[j]?row[j]:''
            })
            _params.push(param)
        });
        return _params



    }

    const uploadRows = async ()=>{
        let promises:any = [];
        let payloads:any = [];
        while (correctRows.length > 20) {
            payloads.push(await createParams(correctRows.splice(0,20)));
        }
        if(correctRows.length > 0){
            payloads.push(await createParams(correctRows));
        }


        let uploadAll = () =>{
            if(payloads.length > 0){
                upload(payloads.shift()).then((resp)=>{
                    console.log(resp)
                    uploadAll();
                }).catch((err:any)=>{
                    console.log(err)
                    uploadAll();
                })
            } else {
                handleClose()
                hideLoader();
                onUploadComplete();
            }

        }
        showLoader();
        uploadAll();
        console.log(payloads)
        /* Promise.all(promises).then((values) => {
            console.log(values);
        }); */
    }

      
   /*  const downloadSampleExcel = async ()=> { 
        let workbookData = [
            {
              workSheet: "data 1",
              rows: [
                { eid: "1", ename: "John", esal: ["val 1", "val2", "val 3"] },
                { eid: "4", ename: "Parker", esal: ["val 1", "val2", "val 3"] },
                { eid: "5", ename: "Iron", esal: ["val 1", "val2", "val 3"] }
              ]
            },
            {
              workSheet: "data 2",
              rows: [
                { eid: "9", ename: "Doe", esal: ["val 1", "val2", "val 3"] },
                { eid: "10", ename: "Peter", esal: ["val 1", "val2", "val 3"] },
                { eid: "11", ename: "Man", esal: ["val 1", "val2", "val 3"] }
              ]
            }
        ]
        const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const EXCEL_EXTENSION = ".xlsx";

        const workbook = new ExcelJS.Workbook();



        workbookData.forEach(({ workSheet, rows }) => {
            const sheet = workbook.addWorksheet(workSheet);
            const uniqueHeaders = [...new Set(rows.reduce((prev, next) => [...prev, ...Object.keys(next)], []))];
            sheet.columns = uniqueHeaders.map(x => ({ header: x, key: x }));

            rows.forEach((jsonRow, i) => {
                let cellValues:any = { ...jsonRow };

                uniqueHeaders.forEach((header, j) => {
                    if (Array.isArray(jsonRow[header])) {
                        cellValues[header] = "";
                    }
                });
                sheet.addRow(cellValues);
                uniqueHeaders.forEach((header, j) => {
                    if (Array.isArray(jsonRow[header])) {
                        const jsonDropdown = jsonRow.esal;
                        sheet.getCell(
                            getSpreadSheetCellNumber(i + 1, j)
                        ).dataValidation = {
                            type: "list",
                            formulae: [`"${jsonDropdown.join(",")}"`]
                        };
                    }
                });
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAsExcelFile(buffer, excelFileName);
    } */
    const downloadSampleExcel = async ()=> { 
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExt = '.xlsx';
        let data:any = {};
        dataTypes.map((field:any)=>{
            data[field.title] = '';
        })
        let ws:any = XLSX.utils.json_to_sheet([data])
        let wb:any = {Sheets:{'data':ws}, SheetNames:['data']}
        let excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'})
        let _data:any = new Blob([excelBuffer],{type:fileType})
        FileSaver.saveAs(_data,sampleName+fileExt)
    }

    useEffect(()=>{
        setCorrectRows(null);
        /* setData(null);
        setHeader(null);
        setPage(1); */

        console.log('dataTypes => ',dataTypes)
    },[show])

    useEffect(()=>{
        (window as any).showInfo = (svg:any)=>{
            console.log(svg);

            let tip = svg.nextElementSibling;
            if(svg.parentElement.parentElement.offsetLeft > 300){
                tip.style.marginLeft = '-300px'
            }
        }

        return ()=>{
            delete (window as any).showInfo
        }
    },[])






    return (
        <Modal show={show} onHide={handleClose} size='xl' scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>{headerTitle ? headerTitle : 'Import farmers'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <label>Choose a .xlsx file</label>
                        <input type='file' onChange={onSelectFile} accept=".xlsx" className='form-control' style={{height:'32px'}} />
                    </div>
                    <div>
                        <Button onClick={downloadSampleExcel}>Download Sample XLSX</Button>
                    </div>
                </div>
                
                

                <hr />
                <div className="table-responsive list-table pb-2" style={{maxHeight:'40vh'}}>
                    <table className="table table-striped csv-table">
                        <thead>
                            <tr ref={tableHeadRowRef}></tr>
                        </thead>
                        <tbody ref={tbodyRef}>

                        </tbody>
                    </table>
                    {/* <Pagination total={total} pageLimit={limit} page={page} setPage={setPage}/> */}
                </div>
                {(correctRows != null) && <h6 className='pt-2'>Got {correctRows?.length} row{correctRows?.length?'s':''} can be imported</h6>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
                <Button variant="primary" type='submit' onClick={uploadRows} disabled={(correctRows == null || correctRows?.length == 0) ? true : false} >Upload </Button>
            </Modal.Footer>
        </Modal>
    )
}